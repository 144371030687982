import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"

export default class IndustrialLubricants extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <section className="wow animate__fadeIn">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 col-md-6 text-center md-margin-30px-bottom wow animate__fadeInLeft">
                <StaticImage
                  src="../../images/s4.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100"
                />
              </div>
              <div className="col-12 col-lg-7 col-md-6 text-center text-md-start padding-eight-lr lg-padding-six-lr md-padding-15px-lr wow animate__fadeInRight" data-wow-delay="0.2s">

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">Industrial lubricants</span>

                <p>Lubricants are kinds of materials which are used for reducing friction and exhaustion of dynamic metal parts are in contact with one another. Accordingly, a subtle layer of lubricant which is called the viscosity of the lubricant is generated between the parts of machines. Each lubricant consists of a basic substance to which several additives are added. This substance can be mineral (with petroleum source) or synthetic (such as polyolefin, polyesters, and polyethylene glycols). Mineral bases are usually vacuuming distillation products and asphalt residues that have been subjected to solvent extraction, waxing, and refining. </p>
                <strong>The benefits of lubricants </strong>
                <p>These materials are useful to make sure of correct movement of parts of a machine. The primary role of these industrial substances is to reduce the friction of surfaces of machines, and durability and enhance their longevity. Since, we know that friction produces heat, lubricants cause to transfer heat from the surface of machines and also helps in cooling process. Due to this, this group of substances is also called heat transfer fluids. Moreover, these substances sometimes reduce the electrical resistance.</p>
                <p>In addition, these substances protect the machines from corroding and rusting during use. Industrial lubricants, oil, and greases can be made of organic or synthetic substances. Most organic lubricants are produced from the refining of petrochemicals, whereas synthetic types of chemicals are made in several reaction stages and the presence of some additives. In general, these materials are available in three forms as liquid, semi-liquid and solid. Engine, turbine, and gear oils are kind of liquid lubricants. </p>

                <a className="btn btn-primary" href="/files/Industrial-Lubricant.pdf">Download</a>

              </div>
            </div>
          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}